/* istanbul ignore file */
import { IDropdownOption } from '@ui-modules/types';
import { BookingDetailsExtra, BookingState, TransferType } from '@common/interfaces';
import { FuelType, SchemaChoiceApi } from './Common';

export enum AcceptStatus {
  Init = 'init',
  Accepting = 'accepting',
  Accepted = 'accepted',
  Rejecting = 'rejecting',
}

export enum DriveEditStatus {
  Blue = 'blue',
  Green = 'green',
  Grey = 'grey',
}

export enum DriveFormSteps {
  ApprovingSharedDrive = 'approvingSharedDrive',
  Confirm = 'confirm',
  Main = 'mainInfo',
  Trip = 'tripInfo',
  ReturnTrip = 'returnTrip',
}

export enum DriveStatus {
  Cancelled = 'cancelled',
  CancelledByUser = 'cancelled_by_user',
  Completed = 'completed',
  Finished = 'finished',
  NotStarted = 'not_started',
  Started = 'started',
}

export enum ProtType {
  Armoured = 'armoured',
  Soft = 'soft',
}

export enum ResViewType {
  Driver = 'driver',
  Vehicle = 'vehicle',
}

export enum Timeline {
  Day = 'Day',
  Week = 'Week',
}

export enum TripType {
  OneWay = 'one-way',
  RoundTrip = 'round-trip',
  MultiLeg = 'multi-leg',
}

export declare type DistanceMatrix = { distance: number; duration: number };
export declare type StepOptions = Record<string, IDropdownOption[]>;
export declare type VerifiedShuttleType = {
  scheduledRouteId: string | null;
  transferType: TransferType;
  typeChanged?: boolean;
};

interface AcceptingDrive {
  driveId: string;
  dropoffDate: string;
  dropoffLocation: string;
  dropoffTime: string;
  dropoffTown: string;
  id: string;
  isActive: boolean;
  pickupDate: string;
  pickupLocation: string;
  pickupTime: string;
  pickupTown: string;
  routeNumber: number;
  signatureCode: string;
}

export interface EventExtProps extends PlDrive {
  isHide?: boolean;
  isQuestWorkflowsEvent?: boolean;
  isShuttleEvent?: boolean;
}

export interface DrivePassengerInfoApi {
  agency: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  pk: string;
  requesting_unit: string;
}

export interface DrivePassengerInfo {
  agency: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  pk: string;
  requestingUnit: IDropdownOption | string;
}

export interface DriveApi {
  booked_route_order: number;
  booking: DriveBookingApi;
  comment_to_driver: string;
  comment_to_pax: string;
  distance: number;
  driver: string;
  dropoff_date: string;
  dropoff_latitude: number | null;
  dropoff_location: string;
  dropoff_longitude: number | null;
  dropoff_time: string;
  dropoff_town: string;
  has_passengers: boolean;
  is_conflicting: boolean;
  is_temporary: boolean;
  pickup_date: string;
  pickup_latitude: number | null;
  pickup_location: string;
  pickup_longitude: number | null;
  pickup_time: string;
  pickup_town: string;
  scheduled_route_id: string | null;
  uuid: string;
  vehicle: string;
}

export interface Drive {
  bookedRouteOrder: number;
  booking: DriveBooking;
  commentToDriver: string;
  commentToPax: string;
  distance: number;
  driver: string;
  dropoffDate: string;
  dropoffLatitude: number | null;
  dropoffLocation: string;
  dropoffLongitude: number | null;
  dropoffTime: string;
  dropoffTown: string;
  hasPassengers: boolean;
  id: string;
  isConflicting: boolean;
  isTemporary: boolean;
  pickupDate: string;
  pickupLatitude: number | null;
  pickupLocation: string;
  pickupLongitude: number | null;
  pickupTime: string;
  pickupTown: string;
  scheduledRouteId: string | null;
  vehicle: string;
}

export interface DriveBookingApi {
  agency: string;
  agency_fk: string;
  agency_short_name: string;
  email: string;
  first_name: string;
  id: string;
  index_number: string;
  is_third_party: boolean;
  last_name: string;
  manager_email: string;
  n_of_passengers: number;
  passengers_info: DrivePassengerInfoApi[];
  phone_number: string;
  purpose: string;
  reference_code: string;
  remarks: string | null;
  requesting_unit: string;
  requesting_unit_display: string;
  state: BookingState;
  transfer_type: TransferType;
  transfer_type_display: string;
  type_of_trip: string;
  type_of_trip_display: string;
}

export interface DriveBooking {
  agency: string;
  agencyFk: string;
  agencyShortName: string;
  email: string;
  firstName: string;
  id: string;
  indexNumber: string;
  isThirdParty: boolean;
  lastName: string;
  managerEmail: string;
  pax: number;
  passengersInfo: DrivePassengerInfo[];
  phoneNumber: string;
  purpose: string;
  refCode: string;
  remarks: string | null;
  requestingUnit: string;
  requestingUnitDisplay: string;
  scheduledRouteId: string | null;
  state: BookingState;
  transferType: TransferType;
  transferTypeDisplay: string;
  typeOfTrip: string;
  typeOfTripDisplay: string;
}

export interface DriveCreateApi {
  comment_to_driver?: string | null;
  comment_to_pax?: string | null;
  driver: string;
  dropoff_date: string;
  dropoff_latitude?: number;
  dropoff_longitude?: number;
  dropoff_location: string;
  dropoff_time: string;
  dropoff_town: string;
  estimated_distance?: number;
  estimated_duration?: number;
  pickup_date: string;
  pickup_latitude?: number;
  pickup_longitude?: number;
  pickup_location: string;
  pickup_time: string;
  pickup_town: string;
  scheduled_route_id?: string | null;
  vehicle: string;
}

export interface DriveCreate {
  commentToDriver: string;
  commentToPax: string;
  driver: IDropdownOption;
  dropoffAddress?: string;
  dropoffAddressRequired?: boolean;
  dropoffDate: Date;
  dropoffLat?: number;
  dropoffLng?: number;
  dropoffLocation: IDropdownOption | string;
  dropoffTime: Date;
  dropoffTown: IDropdownOption | string;
  estimated?: DistanceMatrix;
  pickupAddress?: string;
  pickupAddressRequired?: boolean;
  pickupDate: Date;
  pickupLat?: number;
  pickupLng?: number;
  pickupLocation: IDropdownOption | string;
  pickupTime: Date;
  pickupTown: IDropdownOption | string;
  vehicle: IDropdownOption;
}

export interface DriveCreateExisted {
  agency_id: string;
  booking: string;
  booking_extra?: {
    transfer_type: string;
    uuid: string;
  };
  drives: DriveCreateApi[];
  force_create?: boolean;
}

export interface DriveFormDataApi {
  agent_details?: {
    booking_agent_email: string;
    booking_agent_first_name: string;
    booking_agent_last_name: string;
    booking_agent_username: string;
  };
  booking_details?: {
    agency?: string;
    agency_fk?: string;
    email?: string;
    first_name?: string;
    index_number?: string;
    last_name?: string;
    phone_number?: string;
  };
  booking_extra: {
    manager_email?: string;
    n_of_passengers?: number;
    remarks?: string | null;
    requesting_unit?: string;
    purpose?: string;
  };
  driver_detail: {
    passenger_infos: DrivePassengerInfoApi[];
    transfer_type: string;
    type_of_trip: string;
  };
  drives: DriveCreateApi[];
  force_create?: boolean;
  has_passengers?: boolean;
  is_booking_on_behalf_of_colleague?: boolean;
  recurring_option?: {
    end_date: string;
    frequency: string;
    start_date: string;
    uuid?: string;
    weekdays?: number[];
  };
}

export interface DriveFormState {
  addRecurring?: boolean;
  mainInfo: {
    agency: IDropdownOption;
    email: string;
    firstName: string;
    indexNumber: string;
    lastName: string;
    managerEmail: string;
    noPax?: boolean;
    passengersInfo?: DrivePassengerInfo[];
    pax: IDropdownOption;
    phoneNumber: string;
    remarks?: string;
    requestingUnit: IDropdownOption;
    thirdParty?: boolean;
  };
  returnTrip: {
    driveList: DriveCreate[];
    recurringFrequency?: IDropdownOption;
    recurringPeriod?: IDropdownOption;
    recurringUntil?: Date;
  };
  tripInfo: {
    driveList?: DriveCreate[];
    driveListMultileg?: DriveCreate[];
    purpose?: IDropdownOption;
    recurringFrequency?: IDropdownOption;
    recurringPeriod?: IDropdownOption;
    recurringUntil?: Date;
    transferType: TransferType;
    typeOfTrip?: IDropdownOption;
  };
}

export interface DriveSchemaApi {
  [x: string]: {
    child?: {
      children: {
        [y: string]: {
          choices: SchemaChoiceApi[];
          label: string;
          read_only: boolean;
          required: boolean;
          type: string;
        };
      };
    };
    children: {
      [y: string]: {
        choices: SchemaChoiceApi[];
        label: string;
        read_only: boolean;
        required: boolean;
        type: string;
      };
    };
  };
}

export interface DriveUpdateApi {
  booking: {
    agency: string;
    agency_fk: string;
    email: string;
    first_name: string;
    index_number: string;
    last_name: string;
    manager_email: string;
    n_of_passengers: number;
    passengers_info: DrivePassengerInfoApi[];
    phone_number: string;
    purpose: string;
    remarks: string | null;
    requesting_unit: string;
    transfer_type: TransferType;
    type_of_trip: string;
  };
  comment_to_driver?: string;
  comment_to_pax?: string;
  driver: string | null;
  dropoff_date: string;
  dropoff_latitude?: number;
  dropoff_location: string;
  dropoff_longitude?: number;
  dropoff_time: string;
  dropoff_town: string;
  estimated_distance?: number;
  estimated_duration?: number;
  has_passengers: boolean;
  pickup_date: string;
  pickup_latitude?: number;
  pickup_location: string;
  pickup_longitude?: number;
  pickup_time: string;
  pickup_town: string;
  uuid: string;
  scheduled_route_id: string | null;
  vehicle: string | null;
}

export interface DriveUpdate {
  comment_to_driver?: string | null;
  comment_to_pax?: string | null;
  driver?: string;
  dropoff_date?: string;
  dropoff_location?: string;
  dropoff_time?: string;
  dropoff_town?: string;
  is_active?: boolean;
  pickup_date?: string;
  pickup_location?: string;
  pickup_time?: string;
  pickup_town?: string;
  recurring?: string;
  route?: string;
  state?: DriveStatus;
  uuid: string;
  vehicle?: string;
}

export interface FormDrive {
  commentDriver: string;
  commentPax: string;
  driver: IDropdownOption;
  dropoffDate: Date;
  dropoffTime: Date;
  pickupDate: Date;
  pickupTime: Date;
  vehicle: IDropdownOption;
}

export declare type DriveState = { [key: string | number]: FormDrive };
export declare type PlResource = {
  driverId?: string;
  vehicleId?: string;
};

export interface PlDriverResource {
  resource: {
    extendedProps: Partial<PlDriver>;
    id: string;
    title: string;
  };
}

export interface PlVehicleResource {
  resource: {
    extendedProps: Partial<PlVehicle>;
    id: string;
    title: string;
  };
}

export interface PlAcceptDrive extends AcceptingDrive {
  accepted?: boolean;
  bookingId: string;
  commentDriver?: string | null;
  commentPax?: string | null;
  driverAgency?: string;
  driverEmail?: string;
  driverFirstName?: string;
  driverId?: string;
  driverLastName?: string;
  driverPhone?: string;
  extra?: BookingDetailsExtra | null;
  focalPointFirstName?: string;
  focalPointLastName?: string;
  isHide?: boolean;
  isScheduled?: boolean;
  isTemporary?: boolean;
  passengerAgency?: string;
  passengerEmail?: string;
  passengerFirstName?: string;
  passengerLastName?: string;
  passengerPax?: number;
  passengerPhone?: string;
  passengerRemarks?: string;
  scheduledRouteId?: string | null;
  transferType: TransferType;
  vehicleId?: string;
}

export interface PlDriveApi {
  booking_purpose?: string | null;
  booking_reference_code: string;
  booking_transfer_type?: TransferType;
  booking_type_of_trip?: TripType;
  driver_agency: string;
  driver_email?: string;
  driver_first_name: string;
  driver_id: string;
  driver_last_name: string;
  driver_phone_number?: string;
  dropoff_date: string;
  dropoff_location?: string;
  dropoff_time: string;
  dropoff_town?: string;
  duration: string;
  editable_state: DriveEditStatus;
  is_conflicting: boolean;
  passenger_agency?: string;
  passenger_email?: string;
  passenger_first_name?: string;
  passenger_last_name?: string;
  passenger_number_of_pax?: string;
  passenger_phone_number?: string;
  passenger_remarks?: string | null;
  pickup_date: string;
  pickup_location?: string;
  pickup_time: string;
  pickup_town?: string;
  scheduled_route_id: string | null;
  state: DriveStatus;
  uuid: string;
  vehicle_agency: string;
  vehicle_id: string;
  vehicle_label?: string;
  workflow_description: string;
  workflow_dropoff_date: string;
  workflow_dropoff_time: string;
  workflow_pickup_date: string;
  workflow_pickup_time: string;
}

export interface PlDrive {
  bookingPurpose?: string | null;
  bookingRefCode: string;
  bookingTransferType: TransferType;
  bookingTypeOfTrip?: TripType;
  driverAgency?: string | null;
  driverEmail?: string;
  driverFirstName?: string;
  driverId: string;
  driverLastName?: string;
  driverPhone?: string;
  dropoffDate: string;
  dropoffLocation?: string;
  dropoffTime: string;
  dropoffTown?: string;
  duration?: string;
  editableState?: DriveEditStatus;
  id: string;
  isConflicting: boolean;
  passengerAgency?: string;
  passengerEmail?: string;
  passengerFirstName?: string;
  passengerLastName?: string;
  passengerPax?: number | null;
  passengerPhone?: string;
  passengerRemarks?: string;
  pickupDate: string;
  pickupLocation?: string;
  pickupTime: string;
  pickupTown?: string;
  shuttleConfig?: PlShuttleConfig;
  scheduledRouteId?: string | null;
  state?: DriveStatus;
  vehicleAgency?: string;
  vehicleId: string;
  vehicleLabel?: string;
  workflowDescription?: string;
  workflowDropoffDate?: string;
  workflowDropoffTime?: string;
  workflowPickupDate?: string;
  workflowPickupTime?: string;
}

export interface PlDriveFormData {
  driveStatus?: DriveStatus;
  driverId?: string;
  id?: string;
  resDate?: Date;
  scheduled?: PlDrive;
  stops?: ShuttleStop[];
  vehicleId?: string;
}

export interface PlDriverApi {
  driver_agency: string;
  driver_identifier: string;
  email: string;
  is_disabled: boolean;
  last_name: string;
  name: string;
  phone_number: string;
  photo: string;
  shared: boolean;
}

export interface PlDriver {
  driverAgency: string;
  email: string;
  id: string;
  isDisabled: boolean;
  lastName: string;
  name: string;
  phoneNumber: string;
  shared: boolean;
  photo: string;
  title?: string;
}

export interface PlEvent {
  className: string;
  end: Date;
  id: string;
  resourceId: string;
  start: Date;
  title?: string;
  extendedProps?: Partial<EventExtProps>;
}

export interface PlShuttleApi {
  assignment_id: string;
  assignment_weekdays: number[];
  drive_ref_code: string;
  driver_agency: string;
  driver_email: string;
  driver_id: string;
  driver_label: string;
  driver_phone_number: string;
  dropoff_date: string;
  dropoff_location: string;
  dropoff_time: string;
  dropoff_town: string;
  end_date: string;
  pickup_date: string;
  pickup_location: string;
  pickup_time: string;
  pickup_town: string;
  scheduled_route_id: string;
  start_date: string;
  stops: ShuttleStopsApi[];
  transfer_type: TransferType;
  vehicle_id: string;
  vehicle_label: string;
  vehicle_max_capacity: number;
}

export interface PlShuttleConfig {
  id: string;
  stops: ShuttleStop[];
  weekdays: number[];
}

export interface PlVehicleApi {
  agency_logo: string | null;
  fuel_type: FuelType;
  image: string;
  is_disabled: boolean;
  label: string;
  max_capacity: number;
  plate_number: string;
  preferred_driver: string | null;
  protection: ProtType;
  shared: boolean;
  shared_agency: Array<string>;
  vehicle_agency: string | null;
  vehicle_identifier: string;
}

export interface PlVehicle {
  agencyLogo: string | null;
  fuelType: FuelType;
  id: string;
  image: string;
  isDisabled?: boolean;
  maxCapacity: number;
  plateNumber: string;
  preferredDriver: string | null;
  protection: string;
  shared: boolean;
  sharedAgency: Array<string>;
  title: string;
  vehicleAgency: string | null;
}

export interface QuestWorkflowsApi {
  drive_ref_code: string;
  driver_id: string;
  end_trip_date_time: string;
  start_trip_date_time: string;
  vehicle_id: string;
}

export interface ShuttleConfigFormState {
  driver: IDropdownOption;
  dropoffDate: Date;
  pickupDate: Date;
  vehicle: IDropdownOption;
  weekdays: string[];
}

export interface ShuttleConfigUpdateApi {
  driver_id: string;
  end_date: string;
  scheduled_route_id: string;
  start_date: string;
  uuid: string;
  vehicle_id: string;
  weekdays: number[];
}

export interface ShuttleStopsApi {
  dropoff_time: string;
  location: string;
  pickup_time: string;
  town: string;
}

export interface ShuttleStop {
  dropoffTime: string;
  location: string;
  pickupTime: string;
  town: string;
}
